<template>
  <button :class="style" class="duration-500" @click="$emit('click')" :disabled="disabled">
    <div class="relative flex justify-center items-center">
      <span class="text-sm sm:text-base" :class="{ 'text-transparent': loading }">{{ text }}</span>
      <Icon v-if="icon" :name="icon" :classNames="loading ? 'fill-transparent stroke-transparent' : ''"/>

      <div v-if="loading" class="absolute">
        <Loader size="20px" :color="primary ? 'orange-light' : 'orange'"/>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    primary: {
      type: Boolean,
      default: false
    },
    letter: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      let style = ['border']

      if(this.size === 'small') {
        style.push(this.vertical ? 'px-1 py-4' : 'px-4 py-1')
      } else {
        style.push(this.vertical ? 'px-1 sm:px-3 py-5 sm:py-8' : 'px-3 sm:px-8 py-1 sm:py-3')
      }


      if(this.disabled) {
        style.push('text-white bg-grey-light')
      } else {

      if(!this.primary) {
        style.push('bg-orange-light')
        style.push('text-orange')
        style.push('fill-orange')
        style.push('stroke-orange')
        style.push('border-orange')
        style.push('sm:hover:bg-orange')
        style.push('sm:hover:text-orange-light')
        style.push('sm:hover:fill-orange-light')
        style.push('sm:hover:stroke-orange-light')
        style.push('sm:hover:border-orange-light')
      } else {
        style.push('bg-orange')
        style.push('text-orange-light')
        style.push('stroke-orange-light')
        style.push('border-orange-light')
        style.push('border-orange-light')
        style.push('sm:hover:bg-orange-light')
        style.push('sm:hover:text-orange')
        style.push('sm:hover:fill-orange')
        style.push('sm:hover:stroke-orange')
        style.push('sm:hover:border-orange')
      }
      }

      if(this.rounded) {
        style.push('rounded-full')
      }

      if(this.fullWidth) {
        style.push('w-full')
      }

      return style
    }
  }
}
</script>