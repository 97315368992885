<template>
  <div class="relative w-full px-4 py-1 " :class="border">
    <label class="absolute w-full text-sm sm:text-base" :class="[labelStyle, { hidden: !!val || focus }]">{{ label }}</label>
    <div class="flex justify-between items-center">
      <ValidationProvider :rules="validation" v-slot="{ errors }">
        <input 
          :type="type"
          class="relative w-full focus-visible:outline-none bg-white bg-opacity-0 text-grey text-sm sm:text-base"
          v-model="val"
          :class="input"
          ref="input"
          @focus="focus = true"
          @blur="focus = false"
        />
        <div class="absolute text-orange text-xs top-0">{{ errors[0] }}</div>
      </ValidationProvider>
      <Icon v-if="icon" :name="icon" classNames="fill-green w-8" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputField',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    validation: {
      type: String,
      default: ''
    },
    inputStyle: {
      type: String,
      default: 'rounded'
    },
    inputColor: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      val: null,
      focus: false
    }
  },  
  watch: {
    val() {
      this.$emit('input', this.val)
    },
    value(val) {
      this.val = val
    }
  },
  computed: {
    border() {
      let border = []
      if(this.inputStyle === 'rounded') {
        border.push('border border-orange rounded-full sm:px-6 py-2 sm:py-3')
      }
      if(this.inputStyle === 'underlined') {
        border.push('border-b sm:px-2 sm:py-1')
      }
      if(this.inputStyle === 'underlined-dashed') {
        return 'border-b-2 border-dashed'
      }
      if(this.inputColor === 'green') {
        border.push('border-green')
      }
      return border
    },
    labelStyle() {
      let style = []
      if(this.inputStyle === 'underlined' && !this.icon || this.inputStyle === 'underlined-dashed') {
        style.push('text-center')
      }
      if(this.inputColor === 'green') {
        style.push('text-green')
      } else {
        style.push('text-grey-light')
      }
      if(this.size === 'large') {
        style.push('text-2xl sm:text-4xl font-bold')
      }
      if(this.inputStyle === 'rounded') {
        style.push('top-4')
      }
      return style
    },
    input() {
      let input = []
      if(this.inputStyle === 'underlined' || this.inputStyle === 'underlined-dashed') {
        input.push('text-center')
      }

      if(this.inputColor === 'green') {
        input.push('text-green')
      }
      if(this.size === 'large') {
        input.push('text-xl sm:text-2xl font-bold')
      }
      if(this.inputStyle === 'rounded') {
        input.push('top-1')
      }
      return input
    }
  },
  mounted() {
    this.val = JSON.parse(JSON.stringify(this.value))
  },
  methods: {
    setFocus() {
      this.$refs.input.focus()
    }
  }
}
</script>