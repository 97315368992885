<template>
<div class="sticky top-0 z-10" :class="{ 'shadow-nav': !atTopOfPage }" >
  <nav class="relative flex justify-between items-center bg-orange-light opacity-90 h-18">
    <div class="flex items-center w-2/3 pl-5 sm:pl-14">
      <a href="#/" class="py-4 sm:py-0">
        <img src="@/assets/icons/logo.svg" class="w-10 sm:w-14"/>
      </a>
      <div class="hidden sm:flex text-green ml-14 py-10 space-x-14">
        <router-link v-if="isConnected" to="/">
          <SelectableText :selected="menuActive === 'dashboard'">Dashboard</SelectableText>
        </router-link>
        <router-link to="/quizz">
          <SelectableText :selected="menuActive === 'quizz'">Quizz</SelectableText>
        </router-link>
        <router-link to="/vocabulary">
          <SelectableText :selected="menuActive === 'vocabulary'">Vocabulaire</SelectableText>
        </router-link>
      </div>
    </div>

    <div class="absolute sm:relative w-full h-18 sm:w-auto sm:h-auto sm:right top-0">
      <img src="@/assets/img/navbar-background.png" class="w-full h-18 sm:h-auto" />
    </div>

    <div class="flex justify-end items-center pr-5 sm:pr-14 z-10 absolute right-0">
      <div v-if="isConnected" class="email-logout flex justify-center">
        <Button
          class="absolute logout-btn"
          primary
          rounded
          text="Se déconnecter"
          @click="logout"
        />
        <Button
          class="logout-email"
          primary
          rounded
          :text="email"
          @click="logout"
        />
      </div>
      <Button
        v-if="!isConnected"
        primary
        rounded
        text="Se connecter"
        @click="$store.dispatch('App/setLoginMenu', !showLoginMenu)"
      />
      <IconLink name="burger-menu" classNames="sm:hidden w-6 ml-4" @click="mobileMenuOpen=true" />
    </div>
  </nav>

  <div v-if="mobileMenuOpen" class="fixed w-screen h-screen top-0 bg-orange">
    <div class="bg-orange-light w-full p-5 flex justify-end">
      <div class="w-8">
        <IconLink name="times" classNames="fill-orange" @click="mobileMenuOpen=false" />
      </div>
    </div>
    <div class="flex flex-col text-orange-light text-center" @click="mobileMenuOpen=false">
        <router-link to="/">
          <div class="py-6 border-t border-orange-light">{{ isConnected ? 'Dashboard' : 'Home' }}</div>
        </router-link>
        <router-link to="/quizz">
          <div class="py-6 border-t border-orange-light">Quizz</div>
        </router-link>
        <router-link to="/vocabulary">
          <div class="py-6 border-t border-orange-light">Vocabulaire</div>
        </router-link>
      </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Button from '../components/Button.vue'
import IconLink from '../components/IconLink.vue'

export default {
  name: 'Navbar',
  components: {
    Button,
    IconLink
  },
  data () {
    return {
      loginTab: 0,
      atTopOfPage: true,
      mobileMenuOpen: false
    }
  },
  computed: {
    ...mapGetters({
      menuActive: 'App/getActiveMenu',
      isConnected: 'Auth/isConnected',
      email: 'Auth/getEmail',
      showLoginMenu: 'App/showLoginMenu',
      isSync: 'App/isSync'
    }),
    accountButton() {
      return this.isConnected ? this.email : 'Se connecter'
    }
  },
  beforeMount () {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    logout() {
      this.$emit('close')
      this.$store.dispatch('Auth/logout')
    },
    handleScroll(){
        if(window.pageYOffset>0){
            if(this.atTopOfPage) this.atTopOfPage = false
        }else{
            if(!this.atTopOfPage) this.atTopOfPage = true
        }
    }
  }
}
</script>

<style scoped>
.logout-btn, .logout-email {
  backface-visibility: hidden;
}
.logout-btn {
  transform: rotateX(-180deg);
}

.email-logout:hover .logout-btn, .logout-btn:hover {
  transform: rotateX(0deg);
}
.email-logout:hover + .logout-email, .logout-email:hover, .logout-btn:hover + .logout-email {
  transform: rotateX(180deg);
}

</style>
