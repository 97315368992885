import Vue from 'vue'
import App from './App.vue'
import VueAWN from 'vue-awesome-notifications'
import router from './router'
import store from './store'
import './lib/axios'
import './lib/veeValidate'
import Constants from './lib/constants'
import Requests from './lib/requests'

import Button from './components/Button.vue'
import InputField from './components/InputField.vue'
import Icon from './components/Icon.vue'
import IconLink from './components/IconLink.vue'
import Loader from './components/Loader.vue'
import SelectableText from './components/SelectableText'
Vue.component('Button', Button);
Vue.component('InputField', InputField);
Vue.component('Icon', Icon);
Vue.component('IconLink', IconLink);
Vue.component('Loader', Loader);
Vue.component('SelectableText', SelectableText)

Vue.config.productionTip = false

/**
 * Notifications
 */

const awnOptions = {
 position: 'bottom-right'
}
Vue.use(VueAWN, awnOptions)

/**
 * CONSTANTS
 */
import { Languages } from './constants/Translations'
import { Rights } from './constants/Rights'
import './assets/tailwind.css'
import './registerServiceWorker'
Vue.prototype.$Languages = Languages
Vue.prototype.$Rights = Rights
Vue.prototype.$Constants = Constants;
Vue.prototype.$Requests = Requests


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
