<template>
  <svg viewBox="0 0 16 28" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 26.8C7 27.3523 7.44772 27.8 8 27.8C8.55228 27.8 9 27.3523 9 26.8L7 26.8ZM8.70711 0.492882C8.31658 0.102358 
      7.68342 0.102358 7.29289 0.492882L0.928933 6.85684C0.538408 7.24737 0.538408 7.88053 0.928933 8.27106C1.31946 8.66158 
      1.95262 8.66158 2.34315 8.27106L8 2.6142L13.6569 8.27106C14.0474 8.66158 14.6805 8.66158 15.0711 8.27106C15.4616 7.88053 15.4616 
      7.24737 15.0711 6.85684L8.70711 0.492882ZM9 26.8L9 1.19999L7 1.19999L7 26.8L9 26.8Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowUp'
}
</script>