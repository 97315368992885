import Vue from 'vue'
import Requests from '../../../lib/requests'
import axios from 'axios'
import IDb from '../../../lib/indexdDb'

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];

    array[currentIndex].answer = ''
  }

  return array;
}

const App = {
  namespaced: true,
  state: {
    words: [],
    activeMenu: '',
    hsk: [],
    alphabet: [],
    level: null,
    direction: {},
    showLoginMenu: false,
    loading: false,
    desync: []
  },
  getters: {
    getWords: state => {
      return state.words
    },
    getWordsLength: state => {
      return state.words.length
    },
    getActiveMenu: state => {
      return state.activeMenu
    },
    getQuizz: state => {
      return shuffle(state.words)
    },
    getDirection: state => {
      return state.direction
    },
    getHsk: state => {
      return state.hsk
    },
    getAlphabet: state => {
      return state.alphabet
    },
    getLevel: state => {
      return state.level
    },
    showLoginMenu: state => {
      return state.showLoginMenu
    },
    isLoadingWords: state => {
      return state.loading
    },
    isSync: state => {
      return !state.desync || state.desync.length === 0
    }
  },
  actions: {
    async loadApp({ dispatch, commit }) {
      axios.get('/words')
      axios.get(`/scores`)
      axios.get(`/directions`)

      const desync = await IDb.getAll('desync')

      if(desync && desync.length > 0) {
        commit('setDesync', desync)
        dispatch('synchronize')
      }
      setInterval(() => dispatch('synchronize'), 1000 * 60 * 2);
    },
    loadWords ({ commit }, params) {
      commit('setLoading', true)
      return Requests.getWords(params)
        .then(data => {
          const words = data
          commit('loadWords', words)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
    setActiveMenu ({ commit }, activeMenu) {
      commit('setActiveMenu', activeMenu)
    },
    restartQuizz ({ commit }) {
      commit('restartQuizz')
    },
    setDirection ({ commit }, direction) {
      commit('setDirection', direction)
    },
    setHsk ({ commit }, hsk) {
      commit('setHsk', hsk)
    },
    setAlphabet ({ commit }, alphabet) {
      commit('setAlphabet', alphabet)
    },
    setLevel ({ commit }, level) {
      commit('setLevel', level)
    },
    setLoginMenu ({ commit }, value) {
      commit('setLoginMenu', value)
    },
    addDesync ({ commit }, value) {
      commit('addDesync', value)
    },
    synchronize ({ commit, getters, state }) {
      if(!getters.isSync) {
        console.log('Synchronization', getters.isSync)
        axios.put('/words', state.desync).then(() => {
          IDb.clear('desync')
        }).finally(() => {
          commit('setDesync', [])
        })
      }
    },
  },
  mutations: {
    loadWords (state, words) {
      Vue.set(state, 'words', Object.values(words))
    },
    setActiveMenu(state, activeMenu) {
      Vue.set(state, 'activeMenu', activeMenu)
    },
    setLoginMenu(state, value) {
      Vue.set(state, 'showLoginMenu', value)
    },
    restartQuizz(state) {
      let words = state.words

      words.forEach(word => {
        word.answer = ''
      })

      Vue.set(state, 'words', shuffle(words))
    },
    setDirection (state, direction) {
      Vue.set(state, 'direction', direction)
    },
    setHsk (state, hsk) {
      Vue.set(state, 'hsk', hsk)
    },
    setAlphabet (state, alphabet) {
      Vue.set(state, 'alphabet', alphabet)
    },
    setLevel (state, level) {
      Vue.set(state, 'level', level)
    },
    setLoading (state, loading) {
      Vue.set(state, 'loading', loading)
    },
    addDesync (state, desync) {
      state.desync.push(desync)
    },
    setDesync (state, desync) {
      Vue.set(state, 'desync', desync)
    }
  },
  modules: {}
}

export default App