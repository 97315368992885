<template>
  <svg viewBox="0 0 29 23" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5789 23L28.9367 0.5L0.221158 0.5L14.5789 23Z" />
  </svg>
</template>

<script>
export default {
  name: 'CaretDown'
}
</script>