<template>
  <div id="app" class="bg-orange-light min-h-screen flex flex-col">
    <nav-bar />

    <router-view/>

    <ConnectionForm v-if="showLoginMenu" @close="$store.dispatch('App/setLoginMenu', false)"/>
    <Footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NavBar from '@/elements/NavBar.vue'
import Footer from '@/elements/Footer.vue'
import ConnectionForm from '@/elements/ConnectionForm.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    Footer,
    ConnectionForm
  },
  computed: {
    ...mapGetters({
      showLoginMenu: 'App/showLoginMenu'
    })
  },
  async mounted() {
    await this.$store.dispatch('App/loadApp')
    
  },
  methods: {
    sync() {
      this.$store.dispatch('App/synchronize')
    }
  }
}
</script>

<style>
@import "./css/main.css";
</style>
