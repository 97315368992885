<template>
  <svg viewBox="0 0 29 23" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5789 0L28.9367 22.5L0.221158 22.5L14.5789 0Z"/>
  </svg>

</template>

<script>
export default {
  name: 'CaretUp'
}
</script>