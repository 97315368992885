<template>
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <line x1="1" y1="1" x2="18.4327" y2="0.999998" stroke="white" stroke-width="2" stroke-linecap="round"/>
    <line x1="1" y1="10" x2="18.4327" y2="10" stroke="white" stroke-width="2" stroke-linecap="round"/>
    <line x1="1" y1="19" x2="18.4327" y2="19" stroke="white" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: 'BurgerMenu'
}
</script>