<template>
  <svg viewBox="0 0 37 44" xmlns="http://www.w3.org/2000/svg" style="width: 29px;">
    <line x1="23.6487" y1="1.2732" x2="23.6487" y2="42.2887" stroke-width="2"/>
    <line y1="-1" x2="17.0652" y2="-1" transform="matrix(0.72694 0.6867 -0.927241 0.374466 22.6487 1.2732)" stroke-width="2"/>
    <line x1="13.6216" y1="42.2887" x2="13.6216" y2="1.2732" stroke-width="2"/>
    <line y1="-1" x2="17.0652" y2="-1" transform="matrix(-0.72694 -0.686701 0.927241 -0.374466 14.6216 42.2887)" stroke-width="2"/>
  </svg>
</template>

<script>
export default {
  name: 'DoubleArrow'
}
</script>