<template>
<svg viewBox="0 0 12 13" xmlns="http://www.w3.org/2000/svg">
<path d="M9.82592 11.704C9.99658 11.8107 10.1779 11.848 10.3699 11.816C10.5619 11.7733 10.7273 11.6827 10.8659 11.544C10.9939 
  11.3947 11.0579 11.2133 11.0579 11C11.0579 10.7013 10.9246 10.4667 10.6579 10.296L5.26592 7.064V7.752L10.6579 4.552C10.9246 
  4.392 11.0579 4.152 11.0579 3.832C11.0579 3.52267 10.9246 3.29867 10.6579 3.16C10.3913 3.01067 10.1139 3.01067 9.82592 
  3.16L3.92192 6.712C3.63392 6.91467 3.48992 7.15467 3.48992 7.432C3.48992 7.74133 3.63392 7.98667 3.92192 8.168L9.82592 
  11.704ZM1.28392 13C1.51858 13 1.71592 12.92 1.87592 12.76C2.03592 12.6 2.11592 12.4027 2.11592 12.168V1.336C2.11592 1.09067 
  2.03592 0.893333 1.87592 0.743999C1.71592 0.584 1.51858 0.504 1.28392 0.504C1.03858 0.504 0.841251 0.584 0.691917 0.743999C0.531917 
  0.893333 0.451917 1.09067 0.451917 1.336V12.168C0.451917 12.4027 0.531917 12.6 0.691917 12.76C0.841251 12.92 1.03858 13 1.28392 13Z" 
/>
</svg>

</template>

<script>
export default {
  name: 'Rewind'
}
</script>