<template>
  <svg viewBox="0 0 8 10" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.56705 9.704C1.39638 9.81067 1.21505 9.848 1.02305 9.816C0.831051 9.77333 0.665717 9.68267 0.527051 9.544C0.399051 
      9.39467 0.335051 9.21333 0.335051 9C0.335051 8.70133 0.468384 8.46667 0.735051 8.296L6.12705 5.064V5.752L0.735051 2.552C0.468384 
      2.392 0.335051 2.152 0.335051 1.832C0.335051 1.52267 0.468384 1.29867 0.735051 1.16C1.00172 1.01067 1.27905 1.01067 1.56705 
      1.16L7.47105 4.712C7.75905 4.91467 7.90305 5.15467 7.90305 5.432C7.90305 5.74133 7.75905 5.98667 7.47105 6.168L1.56705 9.704Z" 
    />
  </svg>
</template>

<script>
export default {
  name: 'ChevronRight'
}
</script>