<template>
<svg viewBox="0 0 36 35" xmlns="http://www.w3.org/2000/svg">
  <path 
    d="M34.5365 34.0369C33.2523 35.321 31.1723 35.321 29.8882 34.0369L24.087 28.2357C21.7028 29.7648 18.883 30.6769 15.8384 30.6769C7.36861 
    30.6769 0.5 23.8108 0.5 15.3384C0.5 6.86674 7.36861 0 15.8384 0C24.3101 0 31.1769 6.86611 31.1769 15.3384C31.1769 18.3811 30.2648
    21.2009 28.7357 23.587L34.5369 29.3882C35.821 30.6723 35.821 32.7523 34.5369 34.0365L34.5365 34.0369ZM15.8381 4.38122C9.78769 
    4.38122 4.88085 9.28544 4.88085 15.3385C4.88085 21.3911 9.78725 26.2958 15.8381 26.2958C21.8886 26.2958 26.7954 21.3916 26.7954 
    15.3385C26.7954 9.28588 21.8912 4.38122 15.8381 4.38122Z"

  />
</svg>
</template>

<script>
export default {
  name: 'Search'
}
</script>