import Search from './search.vue'
import Times from './times.vue'
import FastForward from './fast-forward.vue'
import Rewind from './rewind.vue'
import ChevronLeft from './chevron-left.vue'
import ChevronRight from './chevron-right.vue'
import Etc from './etc.vue'
import ArrowUp from './arrow-up.vue'
import CaretUp from './caret-up.vue'
import CaretDown from './caret-down.vue'
import DoubleArrow from './double-arrow.vue'
import CaretLeft from './caret-left'
import CaretRight from './caret-right'
import Recycle from './recycle'
import BurgerMenu from './burger-menu.vue'

export default {
  Search,
  Times,
  Rewind,
  FastForward,
  ChevronLeft,
  ChevronRight,
  Etc,
  ArrowUp,
  CaretDown,
  CaretUp,
  CaretLeft,
  CaretRight,
  DoubleArrow,
  Recycle,
  BurgerMenu
}