import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../pages/dashboard/Dashboard.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/quizz',
    name: 'Quizz',
    component: () => import('../pages/quizz/Quizz.vue')
  },
  {
    path: '/vocabulary',
    name: 'Vocabulary',
    component: () => import('../pages/vocabulary/Vocabulary.vue')
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if(to.path === '/logout') {
    store.dispatch('Auth/logout')
    router.push('/')
  }
  
  store.dispatch('Auth/checkAuth')

  next()
})

export default router
