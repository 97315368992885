<template>
  <div class="preloader-wrapper active self-center" :style="style">
    <div class="spinner-layer" :class="{ 'spinner-green': color === 'green', 'spinner-orange': color === 'orange', 'spinner-orange-light': color === 'orange-light' }">
      <div class="circle-clipper left">
        <div class="circle"></div>
      </div>
      <div class="gap-patch">
        <div class="circle"></div>
      </div>
      <div class="circle-clipper right">
        <div class="circle"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    size: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'orange'
    }
  },
  computed: {
    style() {
      if(!this.size) return null

      return {
        width: this.size,
        height: this.size
      }
    }
  }
}
</script>

<style scoped>
.left {
  float: left !important;
}
.right {
  float: right !important;
}
.preloader-wrapper.active {
  -webkit-animation: container-rotate 1568ms linear infinite;
  animation: container-rotate 1568ms linear infinite;
}
.preloader-wrapper {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}
.spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-color: #26a69a;
}
.spinner-green {
  border-color: var(--green);
}
.spinner-orange {
  border-color: var(--orange);
}
.spinner-orange-light {
  border-color: var(--orange-light);
}
.active .spinner-layer, .active .spinner-layer.spinner-green, .active .spinner-layer.spinner-orange {
  opacity: 1;
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.gap-patch {
  position: absolute;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}
.circle-clipper {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}
.circle-clipper .circle {
  width: 200%;
  height: 100%;
  border-width: 3px;
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  -webkit-animation: none;
  animation: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
.circle-clipper.left .circle {
  left: 0;
  border-right-color: transparent !important;
  -webkit-transform: rotate(129deg);
  transform: rotate(129deg);
}
.circle-clipper.right .circle {
  left: -100%;
  border-left-color: transparent !important;
  -webkit-transform: rotate(-129deg);
  transform: rotate(-129deg);
}
.active .circle-clipper.left .circle {
  -webkit-animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.active .circle-clipper.right .circle {
  -webkit-animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
@keyframes left-spin {
 from {
  -webkit-transform:rotate(130deg);
  transform:rotate(130deg)
 }
 50% {
  -webkit-transform:rotate(-5deg);
  transform:rotate(-5deg)
 }
 to {
  -webkit-transform:rotate(130deg);
  transform:rotate(130deg)
 }
}
@keyframes right-spin {
 from {
  -webkit-transform:rotate(-130deg);
  transform:rotate(-130deg)
 }
 50% {
  -webkit-transform:rotate(5deg);
  transform:rotate(5deg)
 }
 to {
  -webkit-transform:rotate(-130deg);
  transform:rotate(-130deg)
 }
}
@keyframes container-rotate {
 to {
  -webkit-transform:rotate(360deg);
  transform:rotate(360deg)
 }
}
@keyframes fill-unfill-rotate {
 12.5% {
  -webkit-transform:rotate(135deg);
  transform:rotate(135deg)
 }
 25% {
  -webkit-transform:rotate(270deg);
  transform:rotate(270deg)
 }
 37.5% {
  -webkit-transform:rotate(405deg);
  transform:rotate(405deg)
 }
 50% {
  -webkit-transform:rotate(540deg);
  transform:rotate(540deg)
 }
 62.5% {
  -webkit-transform:rotate(675deg);
  transform:rotate(675deg)
 }
 75% {
  -webkit-transform:rotate(810deg);
  transform:rotate(810deg)
 }
 87.5% {
  -webkit-transform:rotate(945deg);
  transform:rotate(945deg)
 }
 to {
  -webkit-transform:rotate(1080deg);
  transform:rotate(1080deg)
 }
}
</style>