<template>
  <svg viewBox="0 0 22 3" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.08198 3C1.78331 3 1.52198 2.89333 1.29798 2.68C1.08465 2.456 0.977978 2.19467 0.977978 1.896C0.977978 1.59733 1.08465 
      1.336 1.29798 1.112C1.51131 0.888 1.77265 0.776 2.08198 0.776C2.39131 0.776 2.65265 0.888 2.86598 1.112C3.07931 1.32533 3.18598 
      1.58667 3.18598 1.896C3.18598 2.20533 3.07398 2.46667 2.84998 2.68C2.63665 2.89333 2.38065 3 2.08198 3ZM5.79398 3C5.49531 3 5.23398 
      2.89333 5.00998 2.68C4.78598 2.456 4.67398 2.19467 4.67398 1.896C4.67398 1.59733 4.78065 1.336 4.99398 1.112C5.21798 0.888 5.48465 
      0.776 5.79398 0.776C6.10331 0.776 6.36465 0.888 6.57798 1.112C6.80198 1.32533 6.91398 1.58667 6.91398 1.896C6.91398 2.19467 6.80198 
      2.456 6.57798 2.68C6.35398 2.89333 6.09265 3 5.79398 3ZM9.50598 3C9.20731 3 8.94598 2.89333 8.72198 2.68C8.50865 2.456 8.40198 
      2.19467 8.40198 1.896C8.40198 1.59733 8.50865 1.336 8.72198 1.112C8.93531 0.888 9.19665 0.776 9.50598 0.776C9.81531 0.776 10.0766 
      0.888 10.29 1.112C10.514 1.336 10.626 1.59733 10.626 1.896C10.626 2.19467 10.514 2.456 10.29 2.68C10.0766 2.89333 9.81531 3 9.50598 
      3ZM12.532 3C12.2333 3 11.972 2.89333 11.748 2.68C11.5346 2.456 11.428 2.19467 11.428 1.896C11.428 1.59733 11.5346 1.336 11.748 
      1.112C11.9613 0.888 12.2226 0.776 12.532 0.776C12.8413 0.776 13.1026 0.888 13.316 1.112C13.5293 1.32533 13.636 1.58667 13.636 
      1.896C13.636 2.20533 13.524 2.46667 13.3 2.68C13.0866 2.89333 12.8306 3 12.532 3ZM16.244 3C15.9453 3 15.684 2.89333 15.46 2.68C15.236 
      2.456 15.124 2.19467 15.124 1.896C15.124 1.59733 15.2306 1.336 15.444 1.112C15.668 0.888 15.9346 0.776 16.244 0.776C16.5533 0.776 
      16.8146 0.888 17.028 1.112C17.252 1.32533 17.364 1.58667 17.364 1.896C17.364 2.19467 17.252 2.456 17.028 2.68C16.804 2.89333 
      16.5426 3 16.244 3ZM19.956 3C19.6573 3 19.396 2.89333 19.172 2.68C18.9586 2.456 18.852 2.19467 18.852 1.896C18.852 1.59733 18.9586 
      1.336 19.172 1.112C19.3853 0.888 19.6466 0.776 19.956 0.776C20.2653 0.776 20.5266 0.888 20.74 1.112C20.964 1.336 21.076 1.59733 
      21.076 1.896C21.076 2.19467 20.964 2.456 20.74 2.68C20.5266 2.89333 20.2653 3 19.956 3Z" 
    />
  </svg>
</template>

<script>
export default {
  name: 'Etc'
}
</script>