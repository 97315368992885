<template>
  <div class="border-none" :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'" @click="click()">
    <Icon :name="name" :classNames="classes"/>
  </div>
</template>

<script>

export default {
  name: 'IconLink',
  props: {
    name: {
      type: String,
      default: ''
    },
    classNames: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      if(this.disabled) {
        return this.classNames + ' fill-grey-light'
      }
      return this.classNames
    }
  },
  methods: {
    click() {
      if(this.disabled) {
        return
      }
      this.$emit('click')
    }
  }

}
</script>