<template>
  <div class="fixed w-screen h-screen bg-filter top-0 flex justify-center items-center z-40">
    <div class="absolute w-full h-full" @click="$emit('close')"></div>
    
    <ValidationObserver ref="form" slim>
    <div class="py-6 px-7 bg-white border border-orange flex flex-col items-center w-96 z-50">
      <Button v-if="!isConnected && showLoginMenu" class="py-2" fullWidth text="Créer un compte" @click="showLoginMenu = false" />
      <Button v-if="!isConnected  && !showLoginMenu" class="py-2" fullWidth text="Se connecter" @click="showLoginMenu = true" />

      <template v-if="!isConnected">
        <div class="border-t border-grey-light w-full my-5"></div>
        <p v-if="showLoginMenu" class="text-orange font-bold text-lg">Se connecter</p>
        <p v-if="!showLoginMenu" class="text-orange font-bold text-lg">Créer mon compte</p>
        <InputField class="mt-5" label="Email*" v-model="email" validation="required|email" />
        <InputField type="password" class="mt-5" label="Mot de passe*" v-model="password" validation="required" />
        <InputField type="password" v-if="!showLoginMenu" class="mt-5" label="Comfirmation du mot de passe*" v-model="confirmation" validation="required" />
        <div class="text-orange text-xs top-0">{{ errorMessage }}</div>
        <Button v-if="showLoginMenu" class="mt-10 py-2" :loading="loading" fullWidth text="Se connecter" @click="login"/>
        <Button v-if="!showLoginMenu" class="mt-10 py-2" :loading="loading" fullWidth text="Créer mon compte" @click="createAccount"/>
      </template>
    </div>
    </ValidationObserver>
  </div>

</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import Button from '../components/Button.vue'
import InputField from '../components/InputField.vue'

export default {
  name: 'ConnectionForm',
  components: {
    Button,
    InputField
  },
  data () {
    return {
      showLoginMenu: true,
      loginTab: 0,
      email: null,
      password: null,
      confirmation: null,
      errorMessage: '',
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      isConnected: 'Auth/isConnected'
    }),
    switchButton() {
      if(this.isConnected) {
        return 'Déconnexion'
      }
      if(this.showLoginMenu) {
        return 'Créer un compte'
      } else {
        return 'Se connecter'
      }

    }
  },
  methods: {
    login() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }

        this.loading = true
        axios.post('/login', { email: this.email, password: this.password })
          .then((res) => {
            this.$emit('close')
            this.$store.dispatch('Auth/setAuth', res.data)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    createAccount() {
      this.errorMessage = ''
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }
        if(this.confirmation !== this.password) {
          this.errorMessage = 'Les mots de passe ne sont pas identiques'
          return false
        }
        this.loading = true

        axios.post('/account/new', { email: this.email, password: this.password, confirmation: this.confirmation })
          .then((res) => {
            this.$emit('close')
            this.$store.dispatch('Auth/setAuth', res.data)
          })
          .finally(() => {
            this.loading = false
          })
      })
    }
  }
}
</script>
