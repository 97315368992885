<template>
  <svg viewBox="0 0 8 10" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.54361 9.704L0.639609 6.168C0.351609 5.98667 0.207609 5.74133 0.207609 5.432C0.207609 5.15467 0.351609 4.91467 
      0.639609 4.712L6.54361 1.16C6.83161 1.01067 7.10894 1.01067 7.37561 1.16C7.64228 1.29867 7.77561 1.52267 7.77561 1.832C7.77561 
      2.152 7.64228 2.392 7.37561 2.552L1.98361 5.752V5.064L7.37561 8.296C7.64228 8.46667 7.77561 8.70133 7.77561 9C7.77561 9.21333 
      7.70628 9.39467 7.56761 9.544C7.43961 9.68267 7.27961 9.77333 7.08761 9.816C6.89561 9.848 6.71428 9.81067 6.54361 9.704Z" 
    />
  </svg>
</template>

<script>
export default {
  name: 'ChevronLeft'
}
</script>