<template>
  <svg viewBox="0 0 17 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.0161 10.0526L0.992153 0.480771L0.992152 19.6245L16.0161 10.0526Z" />
  </svg>

</template>

<script>
export default {
  name: 'CaretRight'
}
</script>