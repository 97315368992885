<template>
    <div v-if="!isConnected" class="py-6 sm:py-16 px-5 sm:px-14">
      <Welcome />
    </div>
    <div v-else class="flex flex-col sm:items-stretch sm:flex-row mx-2 sm:px-0 py-10 sm:py-14 max-w-7xl sm:mx-auto">
        <Loader v-if="loading" />
        <div v-if="!loading" class="bg-white border border-orange p-2 sm:p-10 flex justify-center">
          <table class="text-sm sm:text-base">
            <thead>
              <tr>
                <th rowspan="2"></th>
                <th>Nb.</th>
                <th colspan="3">Progression</th>
                <th rowspan="2">Total</th>
              </tr>
              <tr :key="directions.length">
                <th>mots</th>
                <th class="border-t border-green border-dashed" v-for="dir in directions" :key="'col_' + dir.id">{{ $Constants.abreviations[dir.origin] }} - {{ $Constants.abreviations[dir.target] }}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="border-t-2 border-green border-dashed">
                <td class="text-green"><SelectableText :selected="!hsk || hsk.includes('1')" @click="selectHsk('1')">HSK 1</SelectableText></td>
                <td>{{ byHsk.length && byHsk[1].total }}</td>
                <td v-for="dir in directions" :key="'col_total_' + dir.id">
                  <div class="relative flex justify-center items-center w-14 sm:w-20">
                    <div class="bg-green opacity-100 py-4 mr-auto" :style="{ width: byHsk[1].types[dir.id].percentage * 100 + '%' }"></div>
                    <div class="absolute bg-green opacity-40 w-full py-4"></div>
                    <div class="absolute text-orange-light">{{ Math.round(byHsk[1].types[dir.id].percentage * 100) }}%</div>
                  </div>
                </td>
                <td>{{ byHsk.length && Math.round(byHsk[1].totalScore * 100 / (byHsk[1].total * 6)) }}%</td>
              </tr>
              <tr class="border-t border-green border-dashed">
                <td class="text-green"><SelectableText :selected="!hsk || hsk.includes('2')" @click="selectHsk('2')">HSK 2</SelectableText></td>
                <td>{{ byHsk.length && byHsk[2].total }}</td>
                <td v-for="dir in directions" :key="'col_total_' + dir.id">
                  <div class="relative flex justify-center items-center w-14 sm:w-20">
                    <div class="bg-green opacity-100 py-4 mr-auto" :style="{ width: byHsk[2].types[dir.id].percentage * 100 + '%' }"></div>
                    <div class="absolute bg-green opacity-40 w-full py-4"></div>
                    <div class="absolute text-orange-light">{{ Math.round(byHsk[2].types[dir.id].percentage * 100) }}%</div>
                  </div>
                </td>
                <td>{{ byHsk.length && Math.round(byHsk[2].totalScore * 100 / (byHsk[2].total * 6)) }}%</td>
              </tr>
              <tr class="border-t border-green border-dashed">
                <td class="text-green"><SelectableText :selected="!hsk || hsk.includes('3')" @click="selectHsk('3')">HSK 3</SelectableText></td>
                <td>{{ byHsk.length && byHsk[3].total }}</td>
                <td v-for="dir in directions" :key="'col_total_' + dir.id">
                  <div class="relative flex justify-center items-center w-14 sm:w-20">
                    <div class="bg-green opacity-100 py-4 mr-auto" :style="{ width: byHsk['3'].types[dir.id].percentage * 100 + '%' }"></div>
                    <div class="absolute bg-green opacity-40 w-full py-4"></div>
                    <div class="absolute text-orange-light">{{ Math.round(byHsk[3].types[dir.id].percentage * 100) }}%</div>
                  </div>
                </td>
                <td>{{ byHsk.length && Math.round(byHsk[3].totalScore * 100 / (byHsk[3].total * 6)) }}%</td>
              </tr>
              <tr class="border-t border-green border-dashed">
                <td class="text-green"><SelectableText :selected="!hsk || hsk.includes('4')" @click="selectHsk('4')">HSK 4</SelectableText></td>
                <td>{{ byHsk.length && byHsk[4].total }}</td>
                <td v-for="dir in directions" :key="'col_total_' + dir.id">
                  <div class="relative flex justify-center items-center w-14 sm:w-20">
                    <div class="bg-green opacity-100 py-4 mr-auto" :style="{ width: byHsk['4'].types[dir.id].percentage * 100 + '%' }"></div>
                    <div class="absolute bg-green opacity-40 w-full py-4"></div>
                    <div class="absolute text-orange-light">{{ Math.round(byHsk[4].types[dir.id].percentage * 100) }}%</div>
                  </div>
                </td>
                <td>{{ byHsk.length && Math.round(byHsk[4].totalScore * 100 / (byHsk[4].total * 6)) }}%</td>
              </tr>
              <tr class="border-t border-green border-dashed">
                <td class="text-green"><SelectableText :selected="!hsk || hsk.includes('5')" @click="selectHsk('5')">HSK 5</SelectableText></td>
                <td>{{ byHsk.length && byHsk[5].total }}</td>
                <td v-for="dir in directions" :key="'col_total_' + dir.id">
                  <div class="relative flex justify-center items-center w-14 sm:w-20">
                    <div class="bg-green opacity-100 py-4 mr-auto" :style="{ width: byHsk['5'].types[dir.id].percentage * 100 + '%' }"></div>
                    <div class="absolute bg-green opacity-40 w-full py-4"></div>
                    <div class="absolute text-orange-light">{{ Math.round(byHsk[5].types[dir.id].percentage * 100) }}%</div>
                  </div>
                </td>
                <td>{{ byHsk.length && Math.round(byHsk[5].totalScore * 100 / (byHsk[5].total * 6)) }}%</td>
              </tr>
              <tr class="border-t border-green border-dashed">
                <td class="text-green"><SelectableText :selected="!hsk || hsk.includes('0')" @click="selectHsk('0')">Autre</SelectableText></td>
                <td>{{ byHsk.length && byHsk[0].total }}</td>
                <td v-for="dir in directions" :key="'col_total_' + dir.id">
                  <div class="relative flex justify-center items-center w-14 sm:w-20">
                    <div class="bg-green opacity-100 py-4 mr-auto" :style="{ width: byHsk['0'].types[dir.id].percentage * 100 + '%' }"></div>
                    <div class="absolute bg-green opacity-40 w-full py-4"></div>
                    <div class="absolute text-orange-light">{{ Math.round(byHsk[0].types[dir.id].percentage * 100) }}%</div>
                  </div>
                </td>
                <td>{{ byHsk.length && Math.round(byHsk[0].totalScore * 100 / (byHsk[0].total * 6)) }}%</td>
              </tr>
              <tr class="border-t-2 border-green border-dashed">
                <td class="text-green"><SelectableText :selected="!hsk" @click="selectHsk(null)">Tous</SelectableText></td>
                <td>{{ total }}</td>
                <td v-for="dir in directions" :key="'col_total_' + dir.id">
                  <div class="relative flex justify-center items-center w-14 sm:w-20">
                    <div class="bg-orange opacity-100 py-4 mr-auto" :style="{ width: totalByDirection[dir.id] ? Math.round(totalByDirection[dir.id] * 100 / (total * 2)) + '%' : '0' }"></div>
                    <div class="absolute bg-orange opacity-40 w-full py-4"></div>
                    <div class="absolute text-orange-light">{{ totalByDirection[dir.id] ? Math.round(totalByDirection[dir.id] * 100 / (total * 2)) : '0' }}%</div>
                  </div>
                </td>
                <td>{{ Math.round(totalScore * 100 / (total * 6)) }}%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="!loading" class="flex flex-col justify-end items-center bg-white border-b border-r border-l sm:border-l-0 sm:border-t border-orange p-3 sm:p-10">
          <h5 class="text-green font-bold">Sélection</h5>
          <div class="inline">
            <div class="flex h-64 sm:h-80 w-80 sm:w-96 items-end justify-around pt-5 border-green border-l-2 border-b-2">
              <div class="relative flex flex-col w-12 sm:w-16 mx-3 text-center duration-700" :style="{ height: totalSelected === 0 ? '0%' : '100%' }">
                <div class="opacity-40 duration-700" :style="{ height: ((totalSelected * 6) - totalScoreSelected) * 100 / (totalSelected * 6) + '%', backgroundColor: '#EE5A4D' }"></div>
                <div class="relative duration-700" :style="{ height: totalScoreSelected * 100 / (totalSelected * 6) + '%', backgroundColor: '#EE5A4D' }">
                  <div class="absolute w-full -top-6">{{ totalScoreSelected ? Math.round(totalScoreSelected * 100 / (totalSelected * 6)) + '%' : '0%'}}</div>
                </div>
              </div>
              <div v-for="(dir, index) in directions" :key="index" class="relative flex flex-col w-12 sm:w-16 mx-3 text-center duration-700" :style="{ height: totalSelected === 0 ? 0 : '100%' }">
                <div class="opacity-40 duration-700" :style="{ height: displayedResults[dir.id] ? 100 - displayedResults[dir.id].percentage + '%' : '100%', backgroundColor: '#3B8862' }"></div>
                <div class="relative duration-700" :style="{ height: displayedResults[dir.id] ? displayedResults[dir.id].percentage + '%' : '0%', backgroundColor: '#3B8862' }">
                  <div class="absolute w-full -top-6">{{ hsk && !!hsk.length ? Math.round(displayedResults[dir.id].percentage) + '%' : '0%'}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-around font-bold text-green py-3 text-sm sm:text-base">
            <div class="relative flex flex-col w-16 mx-2 sm:mx-3 text-center">Total</div>
            <div v-for="dir in directions" :key="'dir_' + dir.id" class="relative flex flex-col w-16 mx-2 sm:mx-3 text-center">{{ $Constants.abreviations[dir.origin] }} - {{ $Constants.abreviations[dir.target] }}</div>
          </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Welcome from './Welcome.vue'

export default {
  name: 'Dashboard',
  components: {
    Welcome
  },
  data() {
    return{
      byScore: [],
      byHsk: [],
      total: 0,
      totalScore: 0,
      totalByDirection: {},
      directions: [],
      colors: ['#009688', '#4caf50', '#2196f3', '#ff9800', '#009688'],
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      isConnected: 'Auth/isConnected',
      hsk: 'App/getHsk',
    }),
    displayedResults() {
      if(!this.byHsk.length) return {}

      let results = {}
      let array = []

      if(!this.hsk) {
        array = this.byHsk
      } else {
        array = this.hsk.map((hsk) => this.byHsk[hsk])
      }

      array.forEach(hsk => {
        Object.keys(hsk.types).forEach((id) => {
          if(!results[id]) results[id] = { score: 0, percentage: 0}
          results[id].score += hsk.types[id].score
          results[id].percentage = results[id].score / (this.totalSelected * 0.02)
        })
      })
      return results
    },
    totalSelected() {
      if(!this.byHsk.length) return 0

      if(!this.hsk) {
        return this.byHsk.reduce((acc, opt) => acc += opt.total, 0 )
      }
      return this.hsk.reduce((acc, opt) => acc += this.byHsk[opt].total, 0 )
    },
    totalScoreSelected() {
      return Object.values(this.displayedResults).reduce((acc, res) => acc += res.score, 0)
    }
  },
  watch: {
    isConnected(val) {
      if(val) {
        this.fetchData()
      }
    }
  },
  mounted() {
    this.$store.dispatch('App/setActiveMenu', 'dashboard')
    if(!this.isConnected) {
      return
    }
    
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true

      this.$Requests.getDirections().then((data) => {
        this.directions = data
      })
      
      this.$Requests.getScores().then((data) => {
        this.byScore = data.byScore
        this.byHsk = data.byHsk
        this.total = data.total
        this.totalScore = data.totalScore
        this.totalByDirection = data.totalByDirection

        let hskArray = []
        this.byHsk.forEach((hsk, idx) => {
          if(this.hsk.includes(String(idx))) {
            return
          }

          if(hsk.types['1'].score || hsk.types['2'].score || hsk.types['3'].score) {
            hskArray.push(String(idx))
          }

          this.$store.dispatch('App/setHsk', hskArray)
        })
      }).finally(() => {
        this.loading=false
      })
    },
    selectHsk(value = null) {
      // Select all
      if(!value && this.hsk) {
        this.$store.dispatch('App/setHsk', null)
        return
      }

      let selected = []
      if(!value) {
        this.$store.dispatch('App/setHsk', selected)
        return
      }
      if(!this.hsk) {
        Object.keys(this.byHsk).forEach(key => {
          if(key !== value) {
            selected.push(key)
          }
        })
        this.$store.dispatch('App/setHsk', selected)
        return
      }

      selected = this.hsk
      const indexFound = selected.findIndex((element) => element === value)
      if(indexFound === -1) {
        selected.push(value)
      } else {
        selected.splice(indexFound, 1)
      }

      if(selected.length === this.byHsk.length) {
        this.$store.dispatch('App/setHsk', null)
        return
      }
      this.$store.dispatch('App/setHsk', selected)
    }
  }
}
</script>

<style scoped>
.inline {
  display: flex;
  align-items: center;
  margin-top:10px;
}
</style>
