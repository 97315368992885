<template>
  <svg viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.952148 10.0526L15.9761 0.480771L15.9761 19.6245L0.952148 10.0526Z" />
  </svg>

</template>

<script>
export default {
  name: 'CaretLeft'
}
</script>