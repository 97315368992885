const IDb = {
  data: {
    indexedDb: null
  },
  openDb () {
    return new Promise((resolve, reject) => {
      /* eslint-disable prefer-const */
      let openDBRequest = indexedDB.open('mandarine', 1)
      openDBRequest.onerror = function (error) {
        reject(error)
      }
      openDBRequest.onupgradeneeded = function () {
        let db = this.result
        let objectStore = db.createObjectStore('words')
        objectStore.createIndex("id", "id", { unique: true });

        objectStore = db.createObjectStore('scores')
        objectStore = db.createObjectStore('directions')
        objectStore = db.createObjectStore('desync')
      }

      openDBRequest.onsuccess = function () {
        resolve(this.result)
      }
    })
  },
  clear (object) {
    if (this.data.indexedDb != null) {
      this.data.indexedDb.transaction(object, 'readwrite').objectStore(object).clear()
    }
  },
  store (objectName, data, key) {
    this.openDb()
      .then((indexedDb) => {
        this.data.indexedDb = indexedDb
        let transaction = this.data.indexedDb.transaction([objectName], 'readwrite')
        let objectStore = transaction.objectStore(objectName)

        if(Array.isArray(data)) {
          data.forEach((d) => {
            this.createOrUpdate(objectStore, d[key], d)
          })
        } else {
          this.createOrUpdate(objectStore, key, data)
        }
      })
  },
  update (objectName, key, data) {
    this.openDb()
      .then((indexedDb) => {
        this.data.indexedDb = indexedDb
        let transaction = this.data.indexedDb.transaction([objectName], 'readwrite')
        let objectStore = transaction.objectStore(objectName)

        this.createOrUpdate(objectStore, key, data)
      })
  },
  createOrUpdate(objectStore, key, object) {
    let request
    if(objectStore.get(key)) {
      request = objectStore.put(object, key)
    } else {
      request = objectStore.add(object, key)
    }
    request.onerror = (event) => {
      console.log('error for word id ' + key, event.target.error.message)
    }
  },
  async get(objectName, key) {
    const indexedDb = await this.openDb()
    this.indexedDB = indexedDb

    return new Promise((resolve) => {

      let transaction = indexedDb.transaction([objectName], 'readwrite')
      let objectStore = transaction.objectStore(objectName)
      const req = objectStore.get(key)

      req.onsuccess = (event) => { 
        resolve(event.target.result) }

    })
  },
  async getAll(objectName) {
    const indexedDb = await this.openDb()
    this.indexedDB = indexedDb

    return new Promise((resolve) => {

      let transaction = indexedDb.transaction([objectName], 'readwrite')
      let objectStore = transaction.objectStore(objectName)
      const req = objectStore.getAll()

      req.onsuccess = (event) => { 
        resolve(event.target.result) }

    })
  }
}

export default IDb
