import Vue from 'vue'
import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate';
import fr from 'vee-validate/dist/locale/fr';
import { required, email } from 'vee-validate/dist/rules';

localize('fr', fr);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('required', {
  ...required,
  message: 'Champ obligatoire'
});

extend('email', {
  ...email,
  message: 'Format incorrect'
});