<template>
  <span
    class="relative font-bold cursor-pointer sm:text-lg text-center"
    :class="selected ? 'text-orange' : 'text-green'"
    @click="$emit('click')"
  >
    <slot></slot>
    <div class="absolute bottom-0 border-orange hover:border rounded-full transition-all duration-500" :class="selected ? 'border w-full' : 'w-0 border-0'"></div>
  </span>
</template>

<script>
export default {
  name: 'Button',
  props: {
    selected: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.relative:hover > div {
  width: 100%;
  border-width: 1px;
  display: block;
}
</style>