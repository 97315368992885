<template>
  <footer class="flex justify-center bg-white mt-auto">
    <div class="py-5 text-grey font-semibold">
      <p>Powered by <a href="https://www.cloudnite.net" style="color: #327e82">CloudNite</a></p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>