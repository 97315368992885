<template>
<svg viewBox="0 0 79 79" xmlns="http://www.w3.org/2000/svg">
  <path d="M77.2309 68.689C78.7569 70.2144 79.3525 72.4384 78.794 74.523C78.2354 76.6076 76.6076 78.2353 74.523 78.794C72.4384 
  79.3525 70.2144 78.7569 68.689 77.2309L39.5 48.0419L10.311 77.2309C8.78562 78.7569 6.56162 79.3525 4.47701 78.794C2.3924 
  78.2354 0.764698 76.6076 0.206032 74.523C-0.352514 72.4384 0.243087 70.2144 1.76914 68.689L30.9581 39.5L1.76914 10.311C0.24308 
  8.78562 -0.352549 6.56162 0.206032 4.47701C0.764577 2.3924 2.3924 0.764698 4.47701 0.206031C6.56162 -0.352514 8.78559 0.243087 
  10.311 1.76914L39.5 30.9581L68.689 1.76914C70.2144 0.24308 72.4384 -0.352549 74.523 0.206031C76.6076 0.764577 78.2353 2.3924 
  78.794 4.47701C79.3525 6.56162 78.7569 8.78559 77.2309 10.311L48.0419 39.5L77.2309 68.689Z" 
  />
  </svg>
</template>

<script>
export default {
  name: 'Times'
}
</script>