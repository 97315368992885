<template>
  <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.85674 8.38563C2.41878 8.38563 2.93099 8.03342 3.13212 7.50978L3.20267 7.2533L3.21618 7.20075C3.90425 4.86295 6.08658 3.23273 
      8.53281 3.23273C9.7466 3.23273 10.9183 3.63422 11.88 4.36518L10.6548 5.57049C10.0948 6.12138 10.1553 6.55595 10.2417 6.76603C10.3274 
      6.97579 10.5897 7.328 11.376 7.328H14.8816C15.9562 7.328 16.829 6.45543 16.829 5.38362L16.8288 1.88366C16.8288 1.09816 16.4768 0.83668 
      16.2663 0.750477C16.0559 0.664555 15.6211 0.603485 15.0689 1.16291L13.7928 2.45574C12.3103 1.21094 10.4497 0.521973 8.50128 0.521973C4.85721 
      0.521973 1.60715 2.96118 0.599901 6.44601C0.588378 6.4835 0.484253 6.96523 0.484253 7.0202C0.484253 7.77388 1.10255 8.38563 1.85674 8.38563Z"
    />
    <path d="M17.2142 11.4148C17.2142 11.4701 17.209 11.5211 17.2016 11.5724L17.2197 11.5737C17.2157 11.59 17.2173 11.6058 17.2173 11.6225V11.6241C17.2173 
      11.6555 17.192 11.7129 17.1745 11.7744C17.1672 11.806 17.1463 11.8366 17.1321 11.8745C17.122 11.9124 17.1146 11.9519 17.1028 11.9889C16.0955 15.4742 
      12.8458 17.913 9.20138 17.913C7.25265 17.913 5.39209 17.2245 3.90915 15.9792L2.62566 17.2733C2.2618 17.6419 1.94814 17.7404 1.71528 17.7404C1.59494 
      17.7404 1.49622 17.7139 1.42367 17.6842C1.21245 17.5978 0.858398 17.3356 0.858398 16.5517V13.0515C0.858398 12.0222 1.721 11.2159 2.821 
      11.2159H6.3268C7.08329 11.2159 7.36308 11.5107 7.46507 11.7579C7.56547 12.003 7.57446 12.4016 7.0487 12.9191L5.8423 14.0846C6.80031 14.8069 
      7.96476 15.2023 9.17073 15.2023C11.6172 15.2023 13.7996 13.572 14.4881 11.2342L14.5007 11.1821L14.5717 10.9252C14.7733 10.4016 15.2855 10.0498 
      15.8471 10.0498C16.6016 10.0498 17.2142 10.6619 17.2142 11.4148Z"
    />
  </svg>

</template>

<script>
export default {
  name: 'Recycle'
}
</script>