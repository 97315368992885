import axios from 'axios'
import store from '../../store'
import Vue from 'vue'
import IDb from '../indexdDb'

axios.interceptors.request.use(
  (request) => {
    if (!indexedDB) {
      window.alert("Votre navigateur ne supporte pas certaines fonctionnalités de cette application. Celle-ci ne fontionnera pas correctement sans accès à internet.")
    }
    return request
  }
)

axios.interceptors.response.use(
  (response) => {
    if(response.config.url === '/words' && response.config.method === 'get') {
      IDb.store('words', response.data.words, 'id')
    }

    if(response.config.url === '/scores' && response.config.method === 'get') {
      IDb.store('scores', response.data, 'scores')
    }

    if(response.config.url === '/directions' && response.config.method === 'get') {
      IDb.store('directions', response.data.directions, 'id')
    }
  
    return response
  },
  (error) => {
    console.log(error)
    if (error.response.data.disableGlobal) {
      return Promise.reject(error)
    }

    switch (error.response.status) {
      case 500: {
        Vue.prototype.$awn.warning('Whoops, something went wrong')
        break
      }
      case 400: {
        Vue.prototype.$awn.warning(error.response.data.message)
        break
      }
      case 422: {
        const { errors } = error.response.data
        Object.keys(errors).forEach((key) => {
          Vue.prototype.$awn.warning(errors[key])
        })
        break
      }
      case 401: {
        store.dispatch('Auth/logout')
        break
      }
      default: {
        Vue.prototype.$awn.warning('Whoops, something went wrong')
      }
    }
    return Promise.reject(error)
  }
)

/**
 * Axios configuration
 */
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL || 'http://localhost:8000/api'
axios.defaults.withCredentials = true