import axios from 'axios'
import IDb from './indexdDb'
import Store from '../store'

export default {
  async getDirections() {
    let directions = await IDb.getAll('directions')
      .then((data) => data )

    if(!directions || directions.length === 0) {
      return axios.get('/directions')
        .then((res) =>  res.data.directions)
    }

    return directions
  },
  async getScores() {
    let scores = await IDb.get('scores', 'scores')
      .then((data) => data )

    if(!scores) {
      return axios.get('/scores')
        .then((res) =>  res.data)
    }

    return scores
  },
  async getWords(params) {
    let words = await IDb.getAll('words')
      .then((data) => data )

    if(!words) {
      return words = await axios.get(`/words${params.string}`)
        .then((res) => res.data.words)
    }

    words = words.filter(word => {
      return (
          !params.object.level || 
          !word.scores[params.object.direction] && params.object.level.includes("0") || 
          word.scores[params.object.direction] && params.object.level.includes(word.scores[params.object.direction].level.toString())) &&
        (!params.object.hsk || params.object.hsk.includes(word.hsk.toString())) &&
        (!params.object.alphabet || params.object.alphabet.includes(word.alphabet.toString()))
    })

    return words
  },
  async getVocabulary(params) {
    const nbPerPage = 50
    let words = await IDb.getAll('words')
      .then((data) => data )

    if(!words) {
      return await axios.get(`/vocabulary?page=${params.object.page}${params.string}`)
        .then((res) => res.data)
    }

    const regex = new RegExp(params.object.search,'g')

    words = words.filter(word => {
      return (!params.object.search || word.french.search(regex) != -1 || word.verifier.search(regex) != -1)
    })
    const total = words.length

    const startIdx = params.object.page === 1 ? 0 : ((params.object.page - 1) * nbPerPage) - 1
    const endIdx = startIdx + nbPerPage

    words = words.slice(startIdx, endIdx)

    return { results: words, total: total, nbPerPage: nbPerPage }
  },
  async setScore(score) {
    let word = await IDb.get('words', score.word.id)
      .then((data) => data )

    let scores = await this.getScores()

    await updateScores(scores, word, score)

    word = await updateWord(word, score)
    let response = {success: true}

    if(word.scores[score.direction.id]) {
      response.score = { level: word.scores[score.direction.id].level, direction: score.direction }
    }
    // return axios.put(`score`, score).then(res => res.data)
    return response
  }
}

const updateWord = async (word, score) => {
  if(score.level === 0 && word.scores[score.direction.id]) {
    delete word.scores[score.direction.id]
  }
  if(score.level > 0) {
    word.scores[score.direction.id] = { level: score.level}
  }
  await IDb.update('words', word.id, word)
  await IDb.update('desync', word.id, word)

  Store.dispatch('App/addDesync', word)
  return word
}

const updateScores = async (scores, word, score) => {
  if(
    score.level === 0 && !word.scores[score.direction.id] || 
    word.scores[score.direction.id] && word.scores[score.direction.id] == score.level
  ) {
    return
  }

  const difference = word.scores[score.direction.id] ? score.level - Number(word.scores[score.direction.id].level) : score.level

  if(!scores.totalByDirection[score.direction.id]) {
    scores.totalByDirection[score.direction.id] = 0
  }
  scores.totalByDirection[score.direction.id] += difference
  scores.totalScore += difference

  scores.byHsk[word.hsk].totalScore += difference
  scores.byHsk[word.hsk].types[score.direction.id].score += difference
  scores.byHsk[word.hsk].types[score.direction.id].percentage = Math.round(100* scores.byHsk[word.hsk].types[score.direction.id].score / (scores.byHsk[word.hsk].total * 2)) / 100

  await IDb.update('scores', 'scores', scores)
}