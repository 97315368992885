<template>
  <div :class="classNames">
    <component :is="name" />
  </div>
</template>

<script>
import Icons from '../assets/icons'

export default {
  name: 'Icon',
  components: {
    search: Icons.Search,
    times: Icons.Times,
    'fast-forward': Icons.FastForward,
    rewind: Icons.Rewind,
    'chevron-left': Icons.ChevronLeft,
    'chevron-right': Icons.ChevronRight,
    etc: Icons.Etc,
    'arrow-up': Icons.ArrowUp,
    'caret-up': Icons.CaretUp,
    'caret-down': Icons.CaretDown,
    'double-arrow': Icons.DoubleArrow,
    'recycle': Icons.Recycle,
    'caret-left': Icons.CaretLeft,
    'caret-right': Icons.CaretRight,
    'burger-menu': Icons.BurgerMenu
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    classNames: {
      type: String,
      default: ''
    }
  }

}
</script>