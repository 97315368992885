<template>
  <svg viewBox="0 0 12 13" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.17408 11.704C2.00342 11.8107 1.82208 11.848 1.63008 11.816C1.43808 11.7733 1.27275 11.6827 1.13408 11.544C1.00608 
      11.3947 0.942082 11.2133 0.942082 11C0.942082 10.7013 1.07542 10.4667 1.34208 10.296L6.73408 7.064V7.752L1.34208 4.552C1.07542 
      4.392 0.942082 4.152 0.942082 3.832C0.942082 3.52267 1.07542 3.29867 1.34208 3.16C1.60875 3.01067 1.88608 3.01067 2.17408 
      3.16L8.07808 6.712C8.36608 6.91467 8.51008 7.15467 8.51008 7.432C8.51008 7.74133 8.36608 7.98667 8.07808 8.168L2.17408 
      11.704ZM10.7161 13C10.4814 13 10.2841 12.92 10.1241 12.76C9.96408 12.6 9.88408 12.4027 9.88408 12.168V1.336C9.88408 1.09067 
      9.96408 0.893333 10.1241 0.743999C10.2841 0.584 10.4814 0.504 10.7161 0.504C10.9614 0.504 11.1587 0.584 11.3081 0.743999C11.4681 
      0.893333 11.5481 1.09067 11.5481 1.336V12.168C11.5481 12.4027 11.4681 12.6 11.3081 12.76C11.1587 12.92 10.9614 13 10.7161 13Z" 
    />
  </svg>

</template>

<script>
export default {
  name: 'FastForward'
}
</script>