<template>
    <div class="bg-orange text-white py-14 text-3xl sm:text-5xl max-w-7xl mx-auto mb-12 sm:mb-24">
        <section class="flex flex-col sm:flex-row">
            <div class="flex flex-col justify-end sm:w-1/2 pt-2 sm:pt-12 px-8 sm:px-12 text-center sm:text-left font-bold leading-10 sm:leading-12">
                <p>Un OUTIL pour tester vos CONNAISSANCES du vocabulaire CHINOIS</p>
                <div class="hidden sm:block">
                    <Icon name="times" classNames="w-24 mx-auto mt-24 fill-white" />
                </div>
            </div>
            <div class="sm:w-1/2 mt-10 sm:mt-0">
                <img src="@/assets/img/home-quizz.png" />
            </div>
        </section>

        <section class="flex flex-col sm:flex-row items-center justify-between mt-10 sm:mt-24">
            <div class="sm:hidden text-center px-8 font-bold leading-10">
                <p>PERSONNALISEZ votre test VISUALISEZ votre progression</p>
            </div>
            <div class="sm:w-1/2 mt-10 sm:mt-0">
                <img src="@/assets/img/home-graph.png" />
            </div>
            <div class="hidden sm:block w-2/5 text-right pr-12 font-bold leading-12">
                <p>PERSONNALISEZ votre test VISUALISEZ votre progression</p>
            </div>
        </section>
        <div class="flex justify-center mt-10 sm:mt-16 sm:mb-4 text-base">
            <Button text="Tester mon niveau" @click="goToQuizz"/>
        </div>
    </div>
</template>

<script>
import Button from '../../components/Button.vue'

export default {
    name: 'Welcome',
    components: {
        Button
    },
    methods: {
        goToQuizz() {
            this.$router.push('/quizz')
        }
    }
}
</script>


<style scoped>

</style>