import axios from 'axios'

const localStorageKey = 'mandarine__user_id_'

const Authentication = {
  namespaced: true,
  state: {
    uid: null,
    email: null
  },
  getters: {
    getUid: state => state.uid,
    getEmail: state => state.email,
    isConnected: state => {
      if(state.uid && state.email) {
        return true
      }
      return false
    }
  },
  actions: {
    setAuth({ commit }, auth) {
      commit('setAuth', auth)
    },
    checkAuth({ commit }) {
      const data = JSON.parse(localStorage.getItem(localStorageKey))
      commit('setAuth', data)
    },
    logout({ commit }) {
      commit('logout')
    }
  },
  mutations: {
    setAuth(state, data) {
      if(data == null) {
        return
      }
      localStorage.setItem(localStorageKey, JSON.stringify(data))
      state.uid = data.uid
      state.email = data.email
      axios.defaults.headers.Authorization = state.uid
    },
    logout(state) {
      localStorage.removeItem(localStorageKey)
      state.uid = null
      state.email = null
      delete axios.defaults.headers.Authorization
    }
  }
}

export default Authentication